import React from "react";
import Footer from "src/components/Footer_pt";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="disclosureText">
        </div>
        <div id="page">
          <div id="limit">
            <header id="header">
              <div className="mobileHeaderContainer clearfix">
                <a href="/pt/" className="logo">
                  Piz Buin
                </a>
                <button
                  id="btnShowMenu"
                  className="collapse-btn collapse-short-btn right"
                  data-target=".collapse-menu"
                  data-toggle="collapse"
                  type="button"
                />
              </div>
              <nav id="menu" className="collapse-menu collapse-short-menu">
                <ul className="nav-header">
                  <li className="first has-sub-nav">
                    <a className="es_header" href="/pt/nossos-produtos/" target="_self">
                      Produtos
                    </a>
                    <ul>
                      <li>
                        <a href="/pt/nossos-produtos/hydro-infusion/">
                          Hydro Infusion
                        </a>
                      </li>
                      {/* <li>
                        <a href="/pt/nossos-produtos/active-protect/">
                          Active &amp; Protect
                        </a>
                      </li> */}
                      <li>
                        <a href="/pt/nossos-produtos/moisturising/">
                          Moisturising
                        </a>
                      </li>
                      <li>
                        <a href="/pt/nossos-produtos/tan_protect/">
                          Tan &amp; Protect
                        </a>
                      </li>
                      {/* <li>
                        <a href="/pt/nossos-produtos/instantglow/">
                          Instant Glow
                        </a>
                      </li> */}
                      <li>
                        <a href="/pt/nossos-produtos/allergy/">Allergy</a>
                      </li>
                      <li>
                        <a href="/pt/nossos-produtos/aftersun/">After sun</a>
                      </li>
                      <li>
                        <a href="/pt/nossos-produtos/todos_os_Produtos/">
                          Todos os Produtos
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-selected nav-path-selected">
                    <a className="es_header" href="/pt/a_nossa_historia/" target="_self">
                      A Nossa História
                    </a>
                  </li>
                  <li>
                    <a className="es_header" href="/pt/sobre_o_sol/" target="_self">
                      Aprenda Sobre o Sol
                    </a>
                  </li>
                  {/*<li>
                    <a className="es_header" href="/pt/Por-Onde-Andaste/" target="_self">
                      Por Onde Andaste 2019
                    </a>
                  </li>*/}
                </ul>
                <ul className="countryNav">
                  <li
                    className="collapsible has-sub-nav"
                    style={{
                      "list-style": "none"
                    }}
                  >
                    <a className="countries" href="#">
                      PAÍSES
                    </a>
                    <ul className="subMenu">
                      <li>
                        <a href="/en/our-heritage/">INT</a>
                      </li>
                      <li>
                        <a href="/uk/our-heritage/">UK</a>
                      </li>
                      <li>
                        <a href="/es/nuestra-historia/">ES</a>
                      </li>
                      <li>
                        <a href="/pt/a_nossa_historia/">PT</a>
                      </li>
                      <li>
                        <a href="/it/our-heritage/">IT</a>
                      </li>
                      <li>
                        <a href="/gr/our-heritage/">GR</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
              <div id="menuExtra" />
            </header>
            <section id="desktop-hero-wrapper" className="heritage-hero">
              <div id="bgImage">
                <div className="areaInner">
                  <img
                    border="0"
                    className="ccm-image-block"
                    alt
                    src="/assets/images/our_heritage_1680x405_topLarge.jpg"
                    data-mobile="https://www.pizbuin.com/assets/images/our_heritage_960x405_topSmalla.jpg"
                  />
                </div>
              </div>
            </section>
            
            <div className="page-heritage__intro">
            <h1>Nasce uma marca especialista nos cuidados da pele ao sol</h1>
              <p> </p>
              <p>
                Há mais de 70 anos, PIZ BUIN<sup>®</sup> tem vindo a
                proporcionar aos amantes do sol, por toda parte, soluções de
                proteção solar que os ajudam a alcançar o equilíbrio perfeito
                entre um bronzeado deslumbrante e a proteção que precisam. Na
                verdade, PIZ BUIN<sup>®</sup> foi um dos primeiros a
                proporcionar um bronzeado seguro através da implementação do
                sistema de Fator de Proteção Solar (FPS) na década de 1960. E
                assim, em qualquer lado onde haja sol, seja na praia, na
                montanha ou na cidade, PIZ BUIN<sup>®</sup> tem ajudado as
                pessoas a aproveitar a vida ao sol, de forma segura.
              </p>
              <p>
                Depois de uma exposição excessiva ao sol ao escalar o pico
                alpino Piz Buin em 1938, o estudante de química, Franz Greiter,
                desenvolveu um dos primeiros produtos do mundo de proteção solar
                em 1946, PIZ BUIN<sup>®</sup> "Gletscher Creme" ou Creme
                Glaciar. Juntamente com a sua mulher, Marga, uma esteticista com
                experiência, desenvolveram uma marca especialista em cuidados da
                pele ao sol dedicada ao desenvolvimento de uma avançada e
                luxuosa tecnologia em proteção solar para todos os que quisessem
                desfrutar da vida ao sol. Há mais de 70 anos PIZ BUIN
                <sup>®</sup> tem vindo a proporcionar aos amantes do sol, por
                toda a parte, proteção de alta qualidade para que possam obter a
                deslumbrante cor de verão com que tanto desejam.
              </p>
            </div>
            <section id="content">
              <div id="loading" />
              <div className="innerContent">
                <div >
                  <figure>
                    <img className="year__block" src="/assets/images/h-2019-hydro-infusion-sun-gel-cream-30spf-150ml.jpg" />
                  </figure>
                  <h2 className="year_header">2019</h2>
                  <p></p>
                  <p style={{
                                  textAlign:"center"}}>
                    <strong>Hydro Infusion</strong>
                    <br />
                    LANÇAMENTO DO NOVO HYDRO INFUSION DE PIZ BUIN ®<br />
                    Absorção rápida &amp; Sensação de agua refrescante.
                    <br />
                    De rápida absorção, sem deixar marcas brancas, com uma
                    fórmula não oleosa<br /> e não pegajosa. A sua fórmula Tripla
                    Resistência contém filtros solares<br /> UVA/UVB avançados
                    <br />
                  </p>
                  <p />
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block"src="/assets/images/h-2019-tan-protect-tan-intensifying-sun-oil-spray-30spf-150ml.png" />
                  </figure>
                  <h2 className="year_header">2018</h2>
                  <p></p>
                  <p style={{
                                  textAlign:"center"}}>
                    <strong>Tan &amp; Protect</strong>
                    <br />
                    Lançamento do NOVO SPRAY TAN &amp; PROTECT DE PIZ BUIN ®
                    <br />O Spray Solar Tan &amp; Protect® Intensificador de
                    Bronzeado com Illumitone™<br /> acelera o processo natural do
                    bronzeado* e proporciona uma proteção eficaz<br /> contra os<br /> raios
                    UVA/UVB. Fórmula de absorção imediata, não gordurosa e não<br />
                    pegajosa. Resistente à transpiração e à água. <br />
                    *Testes in vitro.
                    <br />
                  </p>
                  <p />
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block"src="/assets/images/InstantGlow_Heritage.jpg" />
                  </figure>
                  <h2 className="year_header">2017</h2>
                  <p></p>
                  <p style={{
                                  textAlign:"center"}}>
                    <strong>Instant Glow</strong>
                    <br />
                    Lançamento do novo Piz Buin Instant Glow <br />
                    Loção solar iluminadora
                    <br />
                    <br />
                    <br />
                  </p>
                  <p />
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block"src="/assets/images/Protect_and_Cool_Frozen_640_02.jpg" />
                  </figure>
                  <h2 className="year_header">2016</h2>
                  <p></p>
                  <p style={{
                                  textAlign:"center"}}>
                    <strong>Protect &amp; Cool</strong>
                    <br />
                    Lançamento do novo Piz Buin Protect &amp; Cool
                    <br />
                    Uma mousse leve e refrescante, que deixa a pele com uma
                    incrível sensação<br /> de frescura
                    <strong>
                      <br />
                    </strong>
                  </p>
                  <p />
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block"src="/assets/images/70_Years_Celebration_640_02.jpg" />
                  </figure>
                  <h2 className="year_header">2016</h2>
                  <p></p>
                  <p style={{
                                  textAlign:"center"}}>
                    PIZ BUIN® viaja até à Costa Rica com a suas Sun Ambassadors
                    para explorar e<br /> partilhar as suas aventuras ao sol.
                  </p>
                  <p />
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block"src="/assets/images/Sun_Finder_640px_02.jpg" />
                  </figure>
                  <h2 className="year_header">2016</h2>
                  <p></p>
                  <p style={{
                                  textAlign:"center"}}>
                    <strong>Sun Finder</strong>
                    <br />
                    PIZ BUIN® celebra 70 anos de experiência em proteção solar
                    com o<br /> lançamento de PIZ BUIN® Sun Finder – uma applicação
                    web com experiências<br /> e recomendaçãoes<br /> sobre os melhores
                    sítios com sol.
                    <strong>
                      <br />
                    </strong>
                  </p>
                  <p />
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block"src="/assets/images/2015-KV-Instant-Glow-Vertical.jpg" />
                  </figure>
                  <h2 className="year_header">2015</h2>
                  <p></p>
                  <p style={{
                                  textAlign:"center"}}>
                    Lançamento do novo PIZ BUIN INSTANT GLOW<sup>®</sup> Spray
                    Solar Iluminador.<br /> Proteção deslumbrante - Imediata e durante
                    todo o verão.
                  </p>
                  <p />
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block"src="/assets/images/heritage_img_640_2014b.jpg" />
                  </figure>
                  <h2 className="year_header">2014</h2>
                  <p></p>
                  <p style={{
                                  textAlign:"center"}}>
                    Produção da campanha de verão 2014 PIZ BUIN WET SKIN
                    <sup>®</sup> N Spray Solar<br /> Transparente, na Tailândia.
                  </p>
                  <p />
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block"src="/assets/images/heritage_img_640_2014.jpg" />
                  </figure>
                  <h2 className="year_header">2014</h2>
                  <p></p>
                  <p style={{
                                  textAlign:"center"}}>
                    Lançamento dos PIZ BUIN<sup>®</sup> ULTRA LIGHT Fluidos de
                    Proteção Solar Toque<br /> Seco. Proteção eficaz que seca
                    instantaneamente e proporciona uma<br /> sensação de leveza.
                  </p>
                  <p />
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block"src="/assets/images/heritage_img_640_2013.jpg" />
                  </figure>
                  <h2 className="year_header">2013</h2>
                  <p></p>
                  <p style={{
                                  textAlign:"center"}}>
                    Lançamento do PIZ BUIN WET SKIN<sup>®</sup> um dos primeiros
                    protetores
                    <br />
                    solares que podem ser aplicados diretamente em pele molhada.
                  </p>
                  <p />
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block"src="/assets/images/heritage_img_640_2012.jpg" />
                  </figure>
                  <h2 className="year_header">2012</h2>
                  <p></p>
                  <p style={{
                                  textAlign:"center"}}>
                    Lançamento do PIZ BUIN TAN &amp; PROTECT<sup>®</sup>. Para
                    um bronzeado mais rápido*,<br /> deslumbrante e… seguro.
                    <br />
                    *Contém uma tecnologia que estimula o bronzeado natural
                  </p>
                  <p />
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block"src="/assets/images/heritage_img_640_2011.jpg" />
                  </figure>
                  <h2 className="year_header">2011</h2>
                  <p></p>
                  <p style={{
                                  textAlign:"center"}}>
                    65º aniversário e lançamento do novo Creme Glaciar. A
                    inovadora tecnologia<br /> de proteção PIZ BUIN<sup>®</sup> in
                    sun.
                  </p>
                  <p />
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block"src="/assets/images/heritage_img_640_2010.jpg" />
                  </figure>
                  <h2 className="year_header">2010</h2>
                  <p></p>
                  <p style={{
                                  textAlign:"center"}}>
                    PIZ BUIN<sup>®</sup> MOUNTAIN com complexo Cold Shield e
                    extrato Edelweiss.
                    <br />
                    PIZ BUIN<sup>®</sup> MOUNTAIN. Desenvolvido especialmente
                    para proteger, em<br /> condições de altitude elevada, do sol,
                    frio e vento.
                  </p>
                  <p />
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block"src="/assets/images/heritage_img_640_2009.jpg" />
                  </figure>
                  <h2 className="year_header">2009</h2>
                  <p></p>
                  <p style={{
                                  textAlign:"center"}}>
                    PIZ BUIN<sup>®</sup> ALLERGY com Calmanelle<sup>®</sup>, um
                    complexo de proteção único
                    <br />
                    com resultados comprovados no aumento da resistência da pele
                    ao sol*.
                  </p>
                  <p className="footNote">*Teste in vitro</p>
                  <p />
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block"src="/assets/images/heritage_img_640_2008.jpg" />
                  </figure>
                  <h2 className="year_header">2008</h2>
                  <p></p>
                  <p style={{
                                  textAlign:"center"}}>
                    A gama de PIZ BUIN<sup>®</sup> é atualizada com a inovadora
                    tecnologia em proteção<br /> solar, Helioplex<sup>®</sup>.
                  </p>
                  <p />
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block"src="/assets/images/heritage_img_640_2007.jpg" />
                  </figure>
                  <h2 className="year_header">2007</h2>
                  <p style={{
                                  textAlign:"center"}}>
                    Lançamento do PIZ BUIN<sup>®</sup> Tan Intensifier
                    (Intensificador do Bronzeado) – uma<br /> gama de produtos com
                    <br />
                    uma fórmula inovadora que facilita e acelera o bronzeado
                    natural da pele sem<br /> comprometer a proteção.
                  </p>
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block"src="/assets/images/heritage_img_640_2000.jpg" />
                  </figure>
                  <h2 className="year_header">2000</h2>
                  <p style={{
                                  textAlign:"center"}}>
                    Bronzeado intenso dá lugar a “pele deslumbrante, beijada
                    pelo sol”.
                  </p>
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block"src="/assets/images/heritage_img_640_1993.jpg" />
                  </figure>
                  <h2 className="year_header">1993</h2>
                  <p style={{
                                  textAlign:"center"}}>
                    O primeiro PIZ BUIN<sup>®</sup> com tecnologia de proteção
                    solar tripla e Vitamina E.
                  </p>
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block"src="/assets/images/heritage_img_640_1984.jpg" />
                  </figure>
                  <h2 className="year_header">1984</h2>
                  <p style={{
                                  textAlign:"center"}}>
                    “Deusa do Sol” visuais que reforçam a nossa icónica imagem
                    de marca.
                  </p>
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block"src="/assets/images/heritage_img_640_1974.jpg" />
                  </figure>
                  <h2 className="year_header">1974</h2>
                  <p style={{
                                  textAlign:"center"}}>
                    Um dos primeiros a introduzir protetores solares resistentes
                    à água.
                  </p>
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block"src="/assets/images/heritage_img_640_1962b.jpg" />
                  </figure>
                  <h2 className="year_header">1962</h2>
                  <p style={{
                                  textAlign:"center"}}>Realização de testes nos alpes Austríaco-Suíços.</p>
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block"src="/assets/images/heritage_img_640_1962a.jpg" />
                  </figure>
                  <h2 className="year_header">1962</h2>
                  <p style={{
                                  textAlign:"center"}}>
                    Um dos primeiros a implementar o fator de proteção solar
                    (FPS).
                  </p>
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block"src="/assets/images/heritage_img_640_1946.jpg" />
                  </figure>
                  <h2 className="year_header">1946</h2>
                  <p style={{
                                  textAlign:"center"}}>
                    PIZ BUIN<sup>®</sup> lança o seu primeiro protetor solar.
                  </p>
                </div>
                <span className="vline" />
                <div >
                  <figure>
                    <img className="year__block"src="/assets/images/heritage_img_640_1938.jpg" />
                  </figure>
                  <h2 className="year_header">1938</h2>
                  <p></p>
                  <p style={{
                                  textAlign:"center"}}>Dr. Franz Greiter escala a montanha Piz Buin.</p>
                  <p />
                </div>
                <span className="vline" />
              </div>
            </section>
            <section id="social">
              <a
                href="https://www.facebook.com/PizBuinPortugal"
                target="_blank"
                className="fb"
              >
                &nbsp;
              </a>
              <a
                href="https://www.instagram.com/pizbuinportugal/"
                target="_blank"
                className="inst"
              >
                &nbsp;
              </a>
              <a
                href="https://www.youtube.com/channel/UCuRXMca5zj_v97YlGJip63A"
                target="_blank"
                className="yt"
              >
                &nbsp;
              </a>
            </section>
            <div className="clear">
              <br />
            </div>
            <Footer></Footer>
          </div>
          <div id="product-details-overlay" className="itemDescription"></div>
        </div>
        <script src="/assets/js/main.js" />
        <script src="/assets/js/vendor/jquery.fancybox.js" />
        <script src="/assets/js/vendor/jquery.fancybox-media.js" />
        <script src="/assets/js/vendor/iealert.min.js" />
        
        
      </div>
    );
  }
}

export default Page;
